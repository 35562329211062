<!--
 * @Description: 学院课程库
 * @Author: xiawenlong
 * @Date: 2021-04-22 11:43:57
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-28 16:06:56
-->
<template>
  <div class="course-library">
    <el-tabs v-model="activeName">
      <!-- <el-tab-pane label="自建课程" name="1"> -->
      <course-by-self></course-by-self>
      <!-- </el-tab-pane> -->
      <!-- <el-tab-pane label="已购课程" name="2"> -->
      <!-- <course-by-buy></course-by-buy> -->
      <!-- </el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
import CourseBySelf from './components/CourseBySelf'
// import CourseByBuy from './components/CourseByBuy'
export default {
  name: 'CourseLibrary',
  components: {
    CourseBySelf,
    // CourseByBuy,
  },
  data() {
    return {
      activeName: '1',
    }
  },
}
</script>
<style lang="scss" scoped>
.course-library {
  ::v-deep.el-tabs {
    .el-tabs__header {
      font-size: 16px;
      color: #ff7b33;
      line-height: 16px;
      margin-bottom: 20px;
      .el-tabs__nav {
        height: 16px;
        padding-bottom: 7px;
        .el-tabs__item {
          height: 16px;
          line-height: 16px;
        }
      }
    }
  }
}
</style>
