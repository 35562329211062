<!--
 * @Description: 自建课程
 * @Author: xiawenlong
 * @Date: 2021-04-25 17:36:53
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-06-23 16:16:09
-->
<template>
  <div class="course-by-self">
    <table-list
      title="课程列表"
      :loading="loading"
      :data="courseList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <importChapter ref="importChapter" @success="getCourse()"></importChapter>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '导入课程',
    method: _this.importCourse,
  },
  {
    label: '下载课程',
    method: _this.downloadCourse,
  },
  {
    label: '导入课程章节',
    method: _this.handleImport,
  },
  {
    label: '下载章节模板',
    method: _this.handleDownload,
  },
  {
    label: '创建课程',
    method: _this.handleAdd,
  },
  {
    label: '复制副本',
    method: _this.handleCopy,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = () => [
  {
    label: '课程名称',
    prop: 'keyword',
  },
  {
    label: '课程类型',
    type: 'select',
    prop: 'courseType',
    children: [
      { value: 0, label: '录播' },
      { value: 1, label: '直播' },
    ],
  },
  {
    label: '课程状态',
    type: 'select',
    prop: 'enable',
    children: [
      { value: 0, label: '禁用' },
      { value: 1, label: '启用' },
    ],
  },
  {
    label: '上传时间',
    type: 'picker',
    prop: 'createTime',
  },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'courseId',
    label: '课程编号',
  },
  {
    prop: 'mobile',
    label: '课程封面',
    render: (h, { row }) => {
      return [
        row.pic
          ? h('img', {
              domProps: { src: row.pic },
              style: 'height: 50px',
            })
          : null,
      ]
    },
  },
  {
    prop: 'courseName',
    label: '课程名称',
  },
  {
    prop: 'courseType',
    label: '课程类型',
    formatter: row => {
      return { 0: '录播', 1: '直播', 9: '未知' }[row.courseType]
    },
  },
  {
    prop: 'teacherList',
    label: '任课老师',
    formatter: row => {
      if (row.teacherList.length) {
        let nameArray = row.teacherList.map(element => element.teacherName)
        return nameArray.join('/')
      } else {
        return ''
      }
    },
  },
  {
    prop: 'chapterCount',
    label: '章节数',
    align: 'center',
  },
  {
    prop: 'durationCount',
    label: '课程时长',
    align: 'center',
  },
  {
    prop: 'createTime',
    label: '创建时间',
  },
  {
    prop: 'enable',
    label: '可用状态',
    align: 'center',
    render: (h, { row }) => {
      return [
        h('el-switch', {
          props: { value: row.enable },
          on: {
            change: async v => {
              await _this.handleStatusChange(row.courseId, v, row)
            },
          },
        }),
      ]
    },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleEdit,
  },
  {
    label: '章节管理',
    method: _this.handleSection,
  },
]
import importChapter from './importChapter'
import TableList from '@/components/TableList'
import {
  courseList,
  courseStatus,
  courseDelete,
  courseCopy,
  downloadChapter,
  downloadCourse,
} from '@/api/course'
import to from 'await-to'
export default {
  name: 'CourseBySelf',
  components: {
    TableList,
    importChapter,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      operates,
      loading: false,
      courseList: [],
      selectCourse: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
    }
  },
  mounted() {
    this.getCourse()
  },
  methods: {
    async getCourse(queryParams = {}) {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(courseList({ current, size, ...queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.courseList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      if (queryParams.createTime) {
        queryParams.startTime = queryParams.createTime[0]
        queryParams.endTime = queryParams.createTime[1]
      } else {
        queryParams.startTime = ''
        queryParams.endTime = ''
      }
      this.getCourse(queryParams)
    },
    handleAdd() {
      this.$router.push('/course/library/add')
    },
    async handleCopy() {
      if (!this.selectCourse.length) return this.$message.warning('请先选择要复制的课程')
      let courseId = []
      this.selectCourse.map(item => courseId.push(item.courseId))
      const [, err] = await to(courseCopy({ courseId }))
      if (err) return this.$message.warning(err.msg)
      location.reload()
    },
    // 编辑
    handleEdit({ courseId }) {
      this.$router.push(`/course/library/edit/${courseId}`)
    },
    // 章节管理
    handleSection({ courseId, courseName, courseType }) {
      this.$router.push(
        `/course/library/section/${courseId}?courseName=${courseName}&courseType=${courseType}`,
      )
    },
    async handleStatusChange(courseId, enable, row) {
      const [, err] = await to(courseStatus({ courseId, enable }))
      if (err) return this.$message.warning(err.msg)
      row.enable = !row.enable
    },
    // 删除
    async handleDelete() {
      if (!this.selectCourse.length) return this.$message.warning('请先选择要删除的课程')
      let courseId = []
      this.selectCourse.map(item => courseId.push(item.courseId))
      this.$confirm('确认删除课程吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(courseDelete({ courseId }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getCourse()
      })
    },
    handleImport() {
      this.$refs.importChapter.show(0)
      this.$refs['importChapter'].$refs['fileRefs'].$refs['upload-inner'].handleClick()
    },
    async handleDownload() {
      const [res, err] = await to(downloadChapter())
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '章节模板.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    importCourse() {
      this.$refs.importChapter.show(1)
      this.$refs['importChapter'].$refs['fileRefs'].$refs['upload-inner'].handleClick()
    },
    async downloadCourse() {
      const [res, err] = await to(downloadCourse())
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '课程模板.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 多选
    handleSelectionChange(val) {
      this.selectCourse = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCourse()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCourse()
    },
  },
}
</script>
