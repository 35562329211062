<template>
  <div>
    <el-upload
      ref="fileRefs"
      class="upload-demo"
      action="none"
      accept=".xls,.xlsx"
      :show-file-list="false"
      :http-request="fileRequestBackId"
    >
    </el-upload>
  </div>
</template>

<script>
import { importChapter, importCourse, importQuestion } from '@/api/course'
import to from 'await-to'
export default {
  data() {
    return {
      status: '',
      questionStoreId: '',
    }
  },
  created() {},
  methods: {
    show(status, questionStoreId) {
      this.status = status
      this.questionStoreId = questionStoreId
    },
    async fileRequestBackId(file) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      const params = new FormData() //创建FormData
      params.append('file', file.file)
      if (this.status == 0) {
        const [, err] = await to(importChapter(params))
        if (err) {
          loading.close()
          return this.$message.warning({ type: 'warning', message: err.msg })
        }
        this.$message({ type: 'success', message: '导入成功' })
      }
      if (this.status == 1) {
        const [, err] = await to(importCourse(params))
        if (err) {
          loading.close()
          return this.$message.warning({ type: 'warning', message: err.msg })
        }
        this.$message({ type: 'success', message: '导入成功' })
      }
      if (this.status == 2) {
        params.append('questionStoreId', this.questionStoreId)
        const [, err] = await to(importQuestion(params))
        if (err) {
          loading.close()
          return this.$message.warning({ type: 'warning', message: err.msg })
        }
        this.$message({ type: 'success', message: '导入成功' })
      }
      loading.close()
      this.$emit('success')
    },
  },
}
</script>

<style scoped></style>
